import S3 from "react-aws-s3-typescript"

import { isDev, isTest, isProd } from "./utils"

let bucketName = ""
const dirName = "multilingual"
const region = "me-south-1"
let accessKeyId = ""
let secretAccessKey = ""

if (isDev) {
  bucketName = "wadzpay-app-bucket-privatechain-dev"
  accessKeyId = "AKIAWG6SXYEE2ZMPWN7S"
  secretAccessKey = "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"
}

if (isTest) {
  bucketName = "wadzpay-tf-app-bucket-privatechain-test"
  accessKeyId = "AKIAR3T64BWVHHQXJLTH"
  secretAccessKey = "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"
}

if (isProd) {
  bucketName = "wadzpay-tf-app-bucket-privatechain-prod"
  accessKeyId = "AKIAW3MEDJMQAI77MC5E"
  secretAccessKey = "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"
}

const s3Config = {
  bucketName,
  dirName,
  region,
  accessKeyId,
  secretAccessKey
}

const s3 = new S3(s3Config)

export default s3
